import React, { Component } from 'react';
import PlayCircleIcon from '@mui/icons-material/PlayCircle';
import ReplayIcon from '@mui/icons-material/Replay';
import ConfettiExplosion from 'react-confetti-explosion';

class Timer extends Component {
    constructor(props) {
        super(props);
        this.Ref = React.createRef();

        this.state = {
            timer: '01:00',
            timerRunning: false,
            finished: false,
        };
    }

    getTimeRemaining = (e) => {
        const total = Date.parse(e) - Date.parse(new Date());
        const seconds = Math.floor((total / 1000) % 60);
        const minutes = Math.floor((total / 1000 / 60) % 60);
        return {
            total,
            minutes,
            seconds,
        };
    };

    startTimer = (e) => {
        let { total, minutes, seconds } = this.getTimeRemaining(e);
        if (total >= 0) {
            // update the timer
            // check if less than 10 then we need to
            // add '0' at the beginning of the variable
            this.setState({
                timer:
                    (minutes > 9 ? minutes : '0' + minutes) +
                    ':' +
                    (seconds > 9 ? seconds : '0' + seconds)
            });
        }

        if (seconds === 1) {
            this.setState({finished: true});
        }
    };

    clearTimer = (e) => {
        // If you adjust it you should also need to
        // adjust the Endtime formula we are about
        // to code next
        this.setState({ timer: '01:00' });

        // If you try to remove this line the
        // updating of timer Variable will be
        // after 1000ms or 1sec
        if (this.Ref.current) clearInterval(this.Ref.current);
        const id = setInterval(() => {
            this.startTimer(e);
        }, 1000);
        this.Ref.current = id;
        this.setState({timerRunning: false});
    };

    getDeadTime = () => {
        let deadline = new Date();

        // This is where you need to adjust if
        // you extend to add more time
        deadline.setSeconds(deadline.getSeconds() + 60);
        return deadline;
    };

    // We can use componentDidMount so that when the component
    // mount the timer will start as soon as possible
    componentDidMount() {
        // this.clearTimer(this.getDeadTime());
    }

    // Another way to call the clearTimer() to start
    // the countdown is via action event from the
    // button first we create function to be called
    // by the button
    onClickReset = () => {
        this.clearTimer(this.getDeadTime());
        this.setState({timerRunning: true, finished: false});
    };

    render() {

        if (this.state.finished) {
            let audio = new Audio(`${process.env.PUBLIC_URL}/ding-36029.mp3`);
            // TODO - fix not working audio play in iOS
            // audio.play();
        }

        return (
            <div className={'time'}>
                <span>{this.state.timer}</span>

                {!this.state.timerRunning &&
                    <PlayCircleIcon onClick={this.onClickReset} />
                }

                {this.state.timerRunning &&
                    <ReplayIcon onClick={this.onClickReset} />
                }

                {this.state.finished &&
                    <ConfettiExplosion width={window.innerWidth} height={window.innerHeight} tweenDuration={10000} numberOfPieces={400}/>
                }
            </div>
        );
    }
}

export default Timer;
