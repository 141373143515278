import React, {Component} from 'react';
import './home.scss';
import { useSelector, useDispatch, connect } from 'react-redux';
import { bindActionCreators, dispatch } from 'redux';
import MobileNavigation from '../BottomNavigation';
import PackagesList from '../packages/packages';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid2';
import TuneIcon from '@mui/icons-material/Tune';
import { Sidebar } from 'primereact/sidebar';
import { Fieldset } from 'primereact/fieldset';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import { updateUserFilter, updateUserSorting } from '../../store/user/actions';


const mapStateToProps = (state) => {
    return {
        packages: state.packageStore.packages,
        loading: state.packageStore.loading,
        userSelectedSorting: state.userStore.sorting,
        userSelectedFilter: state.userStore.filter
    }
}

const mapDispatchToProps = dispatch => {
    return {
        dispatchUpdateUserSorting: sorting => dispatch(updateUserSorting(sorting)),
        dispatchUpdateUserFilter: filter => dispatch(updateUserFilter(filter)),
    }
};

const filter = [
    { name: 'Hammasi', code: 'all' },
    { name: 'Faqat ochiqlar', code: 'only_open' },
    { name: 'Faqat yopiqlar', code: 'only_closed' },
    { name: 'Faqat 30-savollik', code: 'only_normal' },
    { name: 'Faqat 10-savollik', code: 'only_10_questions' },
    { name: 'Faqat rasmlik', code: 'only_picture' },
];

const sorting = [
    { name: 'To\'plam nomi: A-Z', code: 'name_a_z' },
    { name: 'To\'plam nomi: Z-A', code: 'name_z_a' },
    { name: 'Sana: Eski', code: 'date_a_z' },
    { name: 'Sana: Yangi', code: 'date_z_a' }
];

const sortPackages = (packages, selectedOption) => {
    if (selectedOption === 'name_a_z') {
        packages.sort((a, b) => a.position - b.position);
    } else if (selectedOption === 'name_z_a') {
        packages.sort((a, b) => b.position - a.position);
    } else if (selectedOption === 'date_a_z') {
        packages.sort((a, b) => {
            let da = new Date(a.createdDate),
                db = new Date(b.createdDate);
            return da - db;
        });
    } else if (selectedOption === 'date_z_a') {
        packages.sort((a, b) => {
            let db = new Date(b.createdDate),
                da = new Date(a.createdDate);
            return db - da;
        });
    }
}

const filterPackages = (packages, selectedOption) => {
    const filteredPackages = [];
    packages.forEach(p => {
        if (selectedOption === 'all') {
            filteredPackages.push(p);
        } else if (selectedOption === 'only_open' && p.isPackageFree) {
            filteredPackages.push(p);
        } else if (selectedOption === 'only_closed' && !p.isPackageFree) {
            filteredPackages.push(p);
        } else if (selectedOption === 'only_normal' && p.type === 'NORMAL') {
            filteredPackages.push(p);
        } else if (selectedOption === 'only_10_questions' && p.type === 'TEN_QUESTION') {
            filteredPackages.push(p);
        } else if (selectedOption === 'only_picture' && p.numberOfPictureQuestions > 0) {
            filteredPackages.push(p);
        }
    });

    return filteredPackages;
}

class HomeComponent extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            showSortAndFilterSidebar: false,
            filterApplied: false,
            selectedSortOption: {},
            selectedFilterOption: {},
            isMobile: window.innerWidth < 1200,
            visiblePackages: this.props.packages,
        }

        this.toggleSortSidebar = this.toggleSortSidebar.bind(this);
        this.onChangeSortOption = this.onChangeSortOption.bind(this);
        this.onChangeFilterOption = this.onChangeFilterOption.bind(this);
    }

    componentDidMount() {
        window.addEventListener('resize', () => {
            this.setState({
                isMobile: window.innerWidth < 1200
            });
        }, false);

        let selectedSorting = sorting.find(s => s.code === this.props.userSelectedSorting);

        if (!selectedSorting) {
            selectedSorting = sorting[0];
        }

        let selectedFilter = filter.find(s => s.code === this.props.userSelectedFilter);

        if (!selectedSorting) {
            selectedFilter = filter[0];
        }

        let filteredAndSortedPackages = selectedFilter.code === 'all' ? this.props.packages : filterPackages(this.props.packages, selectedFilter.code);
        sortPackages(filteredAndSortedPackages, selectedSorting.code);
        let updated = filteredAndSortedPackages.length !== 0;

        this.setState({
            visiblePackages: filteredAndSortedPackages,
            selectedSortOption: selectedSorting,
            selectedFilterOption: selectedFilter,
            filterApplied: updated
        });
    }

    toggleSortSidebar(show) {
        this.setState({showSortAndFilterSidebar: show});
    }

    onChangeSortOption(event) {

        const selectedOption = event.target.value;
        if (selectedOption === this.state.selectedSortOption) {
            return;
        }

        const selected = sorting.find(s => s.code === selectedOption);
        this.setState({selectedSortOption: selected});

        sortPackages(this.state.filterApplied ? this.state.visiblePackages : this.props.packages, selectedOption);
        this.props.dispatchUpdateUserSorting(selectedOption);
    }

    onChangeFilterOption(event) {

        const selectedOption = event.target.value;

        if (selectedOption === this.state.selectedFilterOption) {
            return;
        }

        const selected = filter.find(s => s.code === selectedOption);
        this.setState({selectedFilterOption: selected, filterApplied: true});

        const filteredPackages = filterPackages(this.props.packages, selectedOption);
        sortPackages(filteredPackages, this.state.selectedSortOption.code);

        this.setState({visiblePackages: filteredPackages});
        this.props.dispatchUpdateUserFilter(selectedOption);
    }

    render() {

        const sidebarPosition = this.state.isMobile ? 'bottom' : 'right';
        const visiblePackages = this.state.filterApplied ? this.state.visiblePackages : this.props.packages;
        const numberOfQuestions = (visiblePackages.filter(p => p.type === 'NORMAL').length * 30) + (visiblePackages.filter(p => p.type === 'TEN_QUESTION').length * 10);
        const isFilterApplied = this.state.selectedFilterOption.code !== 'all';

        return (
            <div className={'home'}>
                <div className={'header'}>
                    <svg xmlns='http://www.w3.org/2000/svg'
                         xmlnsXlink='http://www.w3.org/1999/xlink'
                         width='500' zoomAndPan='magnify' viewBox='0 0 375 374.999991' height='500' preserveAspectRatio='xMidYMid meet' version='1.0'>
                        <defs>
                            <g/>
                            <clipPath id='id1'>
                                <path d='M 28.875 28.875 L 346.125 28.875 L 346.125 346.125 L 28.875 346.125 Z M 28.875 28.875 ' clip-rule='nonzero'/>
                            </clipPath>
                        </defs>
                        <rect x='-37.5' width='450' y='-37.499999' height='449.999989' fill-opacity='1'/>
                        <rect x='-37.5' width='450' y='-37.499999' height='449.999989' fill-opacity='1'/>
                        <rect x='-37.5' width='450' y='-37.499999' height='449.999989' fill-opacity='1'/>
                        <rect x='-37.5' width='450' y='-37.499999' height='449.999989' fill-opacity='1'/>
                        <g clip-path='url(#id1)'>
                            <path fill='#03989e'
                                  d='M 187.5 28.875 C 100.035156 28.875 28.875 100.035156 28.875 187.5 C 28.875 274.96875 100.035156 346.125 187.5 346.125 C 274.964844 346.125 346.125 274.96875 346.125 187.5 C 346.125 100.035156 274.964844 28.875 187.5 28.875 Z M 187.5 341.992188 C 102.3125 341.992188 33.011719 272.683594 33.011719 187.503906 C 33.011719 102.316406 102.3125 33.011719 187.5 33.011719 C 272.683594 33.011719 341.988281 102.316406 341.988281 187.503906 C 341.992188 272.6875 272.683594 341.992188 187.5 341.992188 Z M 187.5 341.992188 '
                                  fill-opacity='1' fill-rule='nonzero'/>
                        </g>
                        <path fill='#03989e'
                              d='M 187.5 41.257812 C 106.859375 41.257812 41.257812 106.863281 41.257812 187.5 C 41.257812 268.140625 106.859375 333.742188 187.5 333.742188 C 268.140625 333.742188 333.738281 268.136719 333.738281 187.5 C 333.738281 106.863281 268.136719 41.257812 187.5 41.257812 Z M 187.5 41.257812 '
                              fill-opacity='1' fill-rule='nonzero'/>
                        <g fill-opacity='1'>
                            <g transform='translate(116.739263, 277.874982)'>
                                <g>
                                    <path
                                        d='M 103.28125 -171.65625 C 97.507812 -171.84375 86.710938 -171.9375 70.890625 -171.9375 L 44.109375 -171.65625 C 42.617188 -171.46875 40.195312 -171.375 36.84375 -171.375 C 33.675781 -168.59375 30.929688 -164.269531 28.609375 -158.40625 C 26.285156 -152.539062 24.566406 -146.445312 23.453125 -140.125 L 17.578125 -139.84375 C 15.347656 -139.84375 13.769531 -139.9375 12.84375 -140.125 L 9.484375 -180.875 L 16.75 -187.578125 C 40.007812 -186.640625 60.664062 -186.171875 78.71875 -186.171875 C 89.695312 -186.171875 107.742188 -186.640625 132.859375 -187.578125 L 135.375 -176.40625 L 36.28125 -14.515625 L 68.9375 -14.234375 L 104.671875 -14.515625 C 113.421875 -24.929688 119.5625 -36.929688 123.09375 -50.515625 C 125.320312 -50.890625 127.273438 -51.078125 128.953125 -51.078125 C 130.441406 -51.078125 132.488281 -50.890625 135.09375 -50.515625 L 132.578125 0.84375 C 119.742188 0.28125 96.484375 0 62.796875 0 C 49.585938 0 38.003906 0.140625 28.046875 0.421875 C 18.097656 0.703125 11.257812 0.929688 7.53125 1.109375 L 6.703125 -12.84375 Z M 103.28125 -171.65625 '/>
                                </g>
                            </g>
                        </g>
                    </svg>
                    <span>Zakovat savollari</span>
                </div>

                <div className={'filter-container'}>
                    <Box sx={{ flexGrow: 1 }}>
                        <Grid container spacing={2} columns={16}>
                            <Grid size={14}>
                                <Grid size={16}>
                                    To'plam
                                </Grid>
                                <Grid size={16} className={'results'}>
                                    <Grid container spacing={2} columns={16}>
                                        <Grid size={8}>
                                            Jami: {visiblePackages.length}
                                        </Grid>
                                        <Grid size={8} className={'questions'}>
                                            Savollar soni: {numberOfQuestions}
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid container spacing={2} columns={16} style={{marginTop: '20px'}}>
                            <Grid size={8}
                                  onClick={() => this.toggleSortSidebar(true)}
                                  className={'sorting'}>
                                <Fieldset legend='Saralash'>
                                    <p className='m-0'>
                                        {this.state.selectedSortOption.name}
                                    </p>
                                </Fieldset>
                            </Grid>
                            <Grid size={8}
                                  className={'filter'}
                                  onClick={() => this.toggleSortSidebar(true)}>
                                <div>
                                    <TuneIcon className={'filter-icon'} /><span>Filter
                                    {isFilterApplied &&
                                        <span>*</span>
                                    }
                                </span>
                                </div>
                            </Grid>
                        </Grid>
                    </Box>
                </div>

                <PackagesList packages={visiblePackages}/>

                <Sidebar visible={this.state.showSortAndFilterSidebar}
                         position={sidebarPosition}
                         onHide={() => this.toggleSortSidebar(false)}>
                    <h3>Saralash</h3>
                    <div className={'options'}>
                        <RadioGroup
                            aria-labelledby='demo-radio-buttons-group-label'
                            defaultValue='female'
                            value={this.state.selectedSortOption.code}
                            onChange={this.onChangeSortOption}
                            name='radio-buttons-group'
                        >
                            {sorting.map(option => (
                                <FormControlLabel value={option.code} control={<Radio />} label={option.name} />
                            ))}
                        </RadioGroup>
                    </div>
                    <h3>Filter</h3>
                    <div className={'options'}>
                        <RadioGroup
                            aria-labelledby='demo-radio-buttons-group-label'
                            defaultValue='female'
                            value={this.state.selectedFilterOption.code}
                            onChange={this.onChangeFilterOption}
                            name='radio-buttons-group'
                        >
                            {filter.map(option => (
                                <FormControlLabel value={option.code} control={<Radio />} label={option.name} />
                            ))}
                        </RadioGroup>
                    </div>
                </Sidebar>
                <MobileNavigation/>
            </div>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(HomeComponent);
