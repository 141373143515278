import {fetchPackagesBegin, fetchPackagesSuccess, fetchPackagesFailure} from './actions';
import {fetchUser} from "../user/fetchUser";

export function fetchPackages() {

    fetchUser();

    return dispatch => {
        dispatch(fetchPackagesBegin());
        return fetch(`${process.env.REACT_APP_API_URL}/api/packages/`, {
                method: 'GET',
                credentials: 'include'
            })
            .then(handleErrors)
            .then(res => res.json())
            .then(json => {
                dispatch(fetchPackagesSuccess(json));
                return json;
            })
            .catch(error => dispatch(fetchPackagesFailure(error)));
    };
}

export function fetchPackage(id) {
    let data;

    fetch(`${process.env.REACT_APP_API_URL}/api/package/` + id,
        {
            method: 'GET',
            credentials: 'include'
        })
        .then(response => {
            if (!response.ok) {
                return;
            }
        })
        .then(response => response.json())
        .then(json => {
            data = json;
        })

    return data;
}

// Helper function for error handling
function handleErrors(response) {
    if (!response.ok) {
        throw Error(response.statusText);
    }
    return response;
}
