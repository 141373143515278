import React, {Component} from 'react';
import { useSelector, useDispatch, connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import MobileNavigation from '../BottomNavigation';
import FavoriteIcon from '@mui/icons-material/Favorite';
import DownloadForOfflineIcon from '@mui/icons-material/DownloadForOffline';
import fetchSavedQuestions from "../../store/savedQuestion/fetchPackages";
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import './savedQuestions.scss';
import * as CustomUtil from "../CustomUtil";

const mapStateToProps = (state) => {
    return {
        savedQuestions: state.savedQuestionStore.savedQuestions,
        loading: state.savedQuestionStore.loading,
    }
}

class SavedQuestions extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            updated: false
        }

        this.shouldComponentRender = this.shouldComponentRender.bind(this);
        this.deleteSavedQuestion = this.deleteSavedQuestion.bind(this);
    }

    componentDidMount() {
        const { fetchingSavedQuestions } = this.props;
        fetchingSavedQuestions();
    }

    shouldComponentRender() {
        const { loading } = this.props;

        return !this.loading;
    }

    deleteSavedQuestion(questionId) {
        fetch(`${process.env.REACT_APP_API_URL}/api/deleteSavedQuestion/` + questionId,
            {
                method: 'POST',
                credentials: 'include'
            });

        let updatedSavedQuestions = [];
        this.props.savedQuestions.forEach(question => {
            if (question.id !== questionId) {
                updatedSavedQuestions.push(question);
            }
        })
        this.setState({savedQuestions: updatedSavedQuestions, update: true});
    }

    render() {

        let { savedQuestions, loading } = this.props;

        if (!this.shouldComponentRender() || savedQuestions === null) {
            return (<h2>Loading</h2>);
        }

        if (this.state.update) {
            savedQuestions = this.state.savedQuestions
        }

        return (
            <div className={'saved-questions-page'}>
                <h2>Saqlangan savollar</h2>
                {savedQuestions !== undefined && savedQuestions.map(question => (
                    <div className={'question-card'} key={question.positionInPackage}>
                        <Accordion defaultExpanded>
                            <AccordionSummary
                                aria-controls='panel1-content'
                                id={'panel-header-' + question.positionInPackage}
                                className={'panel-header'}
                                expandIcon={<ExpandMoreIcon className={'expand-icon'}/>}
                            >
                                <Typography><span>Savol - {question.positionInPackage + 1}</span><span>{question.packageName}</span></Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Typography className={'body'}>
                                    {CustomUtil.printImage(question)}
                                    <div dangerouslySetInnerHTML={{ __html: question.questionText }} />
                                    <br/>
                                    <div dangerouslySetInnerHTML={{ __html: question.question }} />

                                    <div className={'save-icon'}>
                                        <FavoriteIcon onClick={() => this.deleteSavedQuestion(question.id)}/>
                                    </div>
                                </Typography>

                                <Accordion>
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon className={'expand-icon'}/>}
                                        aria-controls='panel2-content'
                                        className={'panel-header'}
                                        id={'panel-header-answer-' + question.positionInPackage}
                                    >
                                        <Typography>Javobni ko'rish</Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <Typography>
                                            {CustomUtil.printAnswerImage(question)}
                                            <div dangerouslySetInnerHTML={{ __html: question.answer }} />
                                        </Typography>
                                    </AccordionDetails>
                                </Accordion>
                            </AccordionDetails>
                        </Accordion>
                    </div>
                ))}

                <div className={'bottom-space'}></div>
                <MobileNavigation/>
            </div>
        );
    }
}

const mapDispatchToProps = dispatch => bindActionCreators({
    fetchingSavedQuestions: fetchSavedQuestions
}, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(SavedQuestions);
