import './App.scss';
import React, { useEffect } from 'react';
import HomeComponent from './components/home/HomeComponent';
import Price from './components/price/Price';
import SavedQuestions from './components/saved/SavedQuestion';
import Profile from './components/profile/Profile';
import PackageSingleView from './components/view/PackageSingleView';
import {
    BrowserRouter as Router,
    Route,
    Routes,
    RouterProvider
} from 'react-router-dom';
import store from './store/reduxStore'
import {Provider} from 'react-redux';
import {fetchPackages} from './store/package/fetchPackages';
import {fetchUser} from './store/user/fetchUser';
import NoSleep from 'nosleep.js';

function Root() {

    store.dispatch(fetchPackages());
    store.dispatch(fetchUser());

    // working only in Android
    useEffect(() => {
        setTimeout(function (){
            const noSleep = new NoSleep();
            noSleep.enable();
        }, 3000);
    }, []);

    return (

        <div>
            <Provider store={store}>

                <Routes>
                    <Route path='/' element={<HomeComponent/>}/>
                    <Route path='/narxlar' element={<Price/>}/>
                    <Route path='/saved' element={<SavedQuestions/>}/>
                    <Route path='/profil' element={<Profile/>}/>
                    <Route path='/package' element={<PackageSingleView/>}/>
                </Routes>
            </Provider>
        </div>
    );
}

export default Root;
