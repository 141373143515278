import { FETCH_PACKAGES_BEGIN, FETCH_PACKAGES_SUCCESS, FETCH_PACKAGES_FAILURE } from './actionTypes';

const initialState = {
    packages: [],
    loading: false,
    error: null
};

export default function packagesReducer(state = initialState, action) {
    switch(action.type) {
        case FETCH_PACKAGES_BEGIN:
            return {
                ...state,
                loading: true,
                error: null
            };

        case FETCH_PACKAGES_SUCCESS:
            return {
                ...state,
                loading: false,
                packages: action.payload.packages
            };

        case FETCH_PACKAGES_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload.error,
                packages: []
            };

        default:
            return state;
    }
}
