import React, {Component} from 'react';
import './singleView.scss';
import { useSelector, useDispatch, connect } from 'react-redux';
import MobileNavigation from '../BottomNavigation';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid2';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import DownloadForOfflineIcon from '@mui/icons-material/DownloadForOffline';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import FavoriteIcon from '@mui/icons-material/Favorite';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import router from '../../router';
import Modal from '@mui/material/Modal';
import LoginComponent from '../Login';
import {ModalStyle} from '../Util';
import TimerIcon from '@mui/icons-material/Timer';
import CloseIcon from '@mui/icons-material/Close';
import Timer from '../timer';
import PaidIcon from '@mui/icons-material/Paid';
import * as CustomUtil from "../CustomUtil";

const mapStateToProps = (state) => {
    return {
        packages: state.packageStore.packages,
        user: state.userStore.user
    }
}

class PackageSingleView extends Component {

    constructor(props) {
        super();

        this.state = {
            packageDto: {},
            navigate: false,
            showPdfDownloadModal: false,
            showTimeCounter: false,
            questions: []
        }

        this.handleBackButton = this.handleBackButton.bind(this);
        this.handlePdfDownloadModalOpen = this.handlePdfDownloadModalOpen.bind(this);
        this.handlePdfDownloadModalClose = this.handlePdfDownloadModalClose.bind(this);
        this.showTimeCounter = this.showTimeCounter.bind(this);
        this.closeTimeCounter = this.closeTimeCounter.bind(this);
        this.saveQuestion = this.saveQuestion.bind(this);
        this.deleteSavedQuestion = this.deleteSavedQuestion.bind(this);
    }


    componentDidMount() {
        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);

        fetch(`${process.env.REACT_APP_API_URL}/api/package/` + urlParams.get('id'),
            {
                method: 'GET',
                credentials: 'include'
            })
            .then(response => response.json())
            .then(json => {
                this.setState({packageDto: json, questions: json.questions})
            });

        // working only in Android
        setTimeout(function (){
            try {
                navigator.wakeLock.request('screen');
            } catch (err) {
                console.error('Error with wake lock: ', err);
            }
        }, 3000);
    }

    handleBackButton() {
        this.setState({navigate: true});
    }

    handlePdfDownloadModalOpen() {
        this.setState({showPdfDownloadModal: true});
    }

    handlePdfDownloadModalClose() {
        this.setState({showPdfDownloadModal: false});
    }

    showTimeCounter() {
        this.setState({showTimeCounter: true});
    }

    closeTimeCounter() {
        this.setState({showTimeCounter: false});
    }

    saveQuestion(questionId) {
        fetch(`${process.env.REACT_APP_API_URL}/api/savedQuestion/` + questionId,
            {
                method: 'POST',
                credentials: 'include'
            });

        this.setState({questions: this.state.questions.map(x => x.id === questionId ? {...x, saved:true} : x)});
    }

    deleteSavedQuestion(questionId) {
        fetch(`${process.env.REACT_APP_API_URL}/api/deleteSavedQuestion/` + questionId,
            {
                method: 'POST',
                credentials: 'include'
            });

        this.setState({questions: this.state.questions.map(x => x.id === questionId ? {...x, saved:false} : x)});
    }

    get contentForPdfDownloadModal() {
        if (!this.props.user || !this.props.user.email) {
            return (
                <LoginComponent
                text={'To\'plamni PDF formatdan yuklab olish uchun quyidagi veb-servislardan foydalangan holda avtorizatsiyadan oʼtishingiz lozim:'}
                />
            );
        }

        if (!this.state.packageDto.canDownload) {
            return (
                <div className={'cannot-download'}>
                    <Box sx={ModalStyle}>
                        <Typography sx={{ mt: 2 }}>
                            <PaidIcon />
                        </Typography>
                        <Typography sx={{ mt: 2 }}>
                            <span>PDF saqlash tarifi aktivlashmagan!</span>
                        </Typography>
                        <Typography sx={{ mt: 2 }} style={{color: '#a2a2a2'}}>
                            <span>(PDF saqlash tarifini sotib oling)</span>
                        </Typography>
                    </Box>
                </div>

            );
        }

        let compact = `${process.env.REACT_APP_API_URL}/toplamlar/${this.state.packageDto.id}/download?showAnswerInSeparatePage=false`;
        let separate = `${process.env.REACT_APP_API_URL}/toplamlar/${this.state.packageDto.id}/download?showAnswerInSeparatePage=true`;

        return (
            <div className={'pdf-download'}>
                <Box sx={ModalStyle}>
                    <Typography id='pdf-title' variant='h6' component='h2' style={{textAlign: 'center'}}>
                        PDF qilib saqlash
                    </Typography>
                    <Typography id='pdf-download' sx={{ mt: 2 }}>
                        <div className='download-option'>
                            <a href={compact}
                               target={'_blank'}
                               className='compact'>
                                <span>Savol va javobi bir sahifada</span>
                            </a>
                        </div>
                        <div className='download-option'>
                            <a href={separate}
                               target={'_blank'}
                               className='separate'>
                            <span>Javoblari alohida sahifada</span>
                            </a>
                        </div>
                    </Typography>
                </Box>
            </div>
        );
    }

    render () {

        const { navigate } = this.state;
        if (navigate) {
            router.navigate('/');
            return;
        }

        return (
            <div className={'package-single-view'}>


                <div className={'header'}>
                    <Box sx={{ flexGrow: 1 }}>
                        <Grid container spacing={2} columns={16}>
                            <Grid size={2}>
                                <ArrowBackIosNewIcon
                                    onClick={() => this.handleBackButton()}
                                    style={{cursor: 'pointer'}}/>
                            </Grid>
                            <Grid size={12}>
                                <div className={'title'}>
                                    {this.state.packageDto.name}
                                </div>
                            </Grid>
                            <Grid size={2}>
                                <div className={'pdf'}
                                     onClick={() => this.handlePdfDownloadModalOpen()}>
                                    <DownloadForOfflineIcon/>
                                    <div>PDF</div>
                                </div>
                            </Grid>
                        </Grid>
                        <Grid container spacing={2} columns={16} className={'detail'}>
                            <Grid size={16}>
                                <div className={'text'}>
                                    <span>{this.state.packageDto.totalQuestion} savol</span>
                                    <span>{this.state.packageDto.numberOfPictureQuestions} rasmli</span>
                                </div>
                            </Grid>
                        </Grid>
                    </Box>
                </div>
                {this.state.questions !== undefined && this.state.questions.map(question => (
                    <div className={'question-card'} key={question.positionInPackage}>
                        <Accordion defaultExpanded>
                            <AccordionSummary
                                aria-controls='panel1-content'
                                id={'panel-header-' + question.positionInPackage}
                                className={'panel-header'}
                                expandIcon={<ExpandMoreIcon className={'expand-icon'}/>}
                            >
                                <Typography>Savol - {question.positionInPackage + 1}</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Typography className={'body'}>
                                    {CustomUtil.printImage(question)}
                                    <div dangerouslySetInnerHTML={{ __html: question.questionText }} />
                                    <br/>
                                    <div dangerouslySetInnerHTML={{ __html: question.question }} />

                                    <div className={'save-icon'}>
                                        {!question.saved &&
                                            <FavoriteBorderIcon onClick={() => this.saveQuestion(question.id)} />
                                        }
                                        {question.saved &&
                                            <FavoriteIcon onClick={() => this.deleteSavedQuestion(question.id)}/>
                                        }
                                    </div>
                                </Typography>

                                <Accordion>
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon className={'expand-icon'}/>}
                                        aria-controls='panel2-content'
                                        className={'panel-header'}
                                        id={'panel-header-answer-' + question.positionInPackage}
                                    >
                                        <Typography>Javobni ko'rish</Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <Typography>
                                            {CustomUtil.printAnswerImage(question)}
                                            <div dangerouslySetInnerHTML={{ __html: question.answer }} />
                                        </Typography>
                                    </AccordionDetails>
                                </Accordion>
                            </AccordionDetails>
                        </Accordion>


                    </div>
                ))}

                <div className={'timer-container'}>
                    {this.state.showTimeCounter &&
                        <Timer />
                    }
                    {!this.state.showTimeCounter &&
                        <TimerIcon onClick={() => this.showTimeCounter()}/>
                    }
                    {this.state.showTimeCounter &&
                        <CloseIcon onClick={() => this.closeTimeCounter()}/>
                    }
                </div>

                <div className={'bottom-space'}>SPACE</div>

                <Modal
                    open={this.state.showPdfDownloadModal}
                    onClose={this.handlePdfDownloadModalClose}
                    aria-labelledby='modal-modal-title'
                    aria-describedby='modal-modal-description'
                >
                    {this.contentForPdfDownloadModal}
                </Modal>
                <MobileNavigation/>
            </div>
        );
}

}

export default connect(mapStateToProps)(PackageSingleView);
