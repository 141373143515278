export function printImage(question) {
    if (question.questionImageUrl) {
        return (<img src={question.questionImageUrl} alt={'Question Image'}/>);
    }

    return '';
}

export function printAnswerImage(question) {
    if (question.answerImageUrl) {
        return (<img src={question.answerImageUrl} alt={'Question Image'}/>);
    }

    return '';
}
