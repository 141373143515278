import { configureStore } from '@reduxjs/toolkit'
// import packagesReducer from './package/reducer';
//
// export default configureStore({
//     reducer: {
//         packagesReducer
//     },
// })

import { createStore, combineReducers, applyMiddleware } from 'redux';
import {thunk} from 'redux-thunk';
import packagesReducer from './package/reducer';
import userReducer from "./user/reducer";
import savedQuestionReducer from "./savedQuestion/reducer";


export default createStore (
    combineReducers({
        userStore: userReducer,
        packageStore: packagesReducer,
        savedQuestionStore: savedQuestionReducer
    }),
    applyMiddleware(thunk)
);
