import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { fetchPackages } from '../../store/package/fetchPackages';
import PackageCard from './../package/PackageComponent';
import CssBaseline from '@mui/material/CssBaseline';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';
import Grid from '@mui/material/Grid';
import ReactLoading from 'react-loading';
import './packages.scss';

const PackagesList = ({packages}) => {
    const dispatch = useDispatch();
    // const packages = useSelector(state => state.packageStore.packages);
    const user = useSelector(state => state.userStore.user);
    const loading = useSelector(state => state.packageStore.loading);
    const error = useSelector(state => state.packageStore.error);

    // no needed because of loading in Root.js
    // useEffect(() => {
    //     // if (loading || error) {
    //         dispatch(fetchPackages());
    //     // }
    // }, [dispatch]);

    if (error) {
        return <div>Error! {error.message}</div>;
    }

    if (loading) {
        return (
            <div className={'loading'}>
                <ReactLoading type={'spin'}
                              color={'#838383'}
                              height={'20%'}
                              width={'20%'} />
            </div>

        )
    }

    const Item = styled(Paper)(() => ({
        backgroundColor: 'white',
        padding: 0,
        textAlign: 'center',
        color: 'black',
    }));

    return (
        <div>
            <Container>
                <Grid container spacing={4} style={{marginBottom: '100px'}}>
                    {packages.map(packageDto => (
                        <Grid item xs={6} md={3}>
                            <Item elevation={3}>
                                <PackageCard key={packageDto.id} zPackage={packageDto} user={user} />
                            </Item>
                        </Grid>

                    ))}
                </Grid>


            </Container>

        </div>
    );
};

export default PackagesList;
