import React, { useEffect, useState } from 'react';
import './package.scss';
import { useNavigate } from 'react-router-dom';
import LoginComponent from '../Login';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import LoginModalComponent from '../LoginModal';
import PriceModalComponent from '../PriceModal';
import {ModalStyle} from '../Util';

const PackageCard = ({zPackage, user}) => {
    const navigate = useNavigate();
    const [loginRequired, setLoginRequired] = useState(false);
    const [showPrice, setShowPrice] = useState(false);

    const handleClose = () => setLoginRequired(false);

    const getLockIcon = (free) => {

        if (free) {
            return (<svg viewBox='0 0 24 24'>
                <path fill='currentColor' d='M18,8A2,2 0 0,1 20,10V20A2,2 0 0,1 18,22H6C4.89,22 4,21.1 4,20V10A2,2 0 0,1 6,8H15V6A3,3 0 0,0 12,3A3,3 0 0,0 9,6H7A5,5 0 0,1 12,1A5,5 0 0,1 17,6V8H18M12,17A2,2 0 0,0 14,15A2,2 0 0,0 12,13A2,2 0 0,0 10,15A2,2 0 0,0 12,17Z'></path>
            </svg>);
        }

        return (<svg viewBox='0 0 24 24' style={{color: 'red'}}>
            <path fill='currentColor' d='M12,17A2,2 0 0,0 14,15C14,13.89 13.1,13 12,13A2,2 0 0,0 10,15A2,2 0 0,0 12,17M18,8A2,2 0 0,1 20,10V20A2,2 0 0,1 18,22H6A2,2 0 0,1 4,20V10C4,8.89 4.9,8 6,8H7V6A5,5 0 0,1 12,1A5,5 0 0,1 17,6V8H18M12,3A3,3 0 0,0 9,6V8H15V6A3,3 0 0,0 12,3Z' />
        </svg>);
    }

    function handleClick(zPackage, user) {
        if (zPackage.isPackageFree) {
            navigate('/package?id=' + zPackage.id)
        } else if (user === undefined || user.name === undefined || user.name === null) {
            setLoginRequired(true);
        } else {
            setShowPrice(true);
        }
    }

    return (
        <div className={'package-card'}
             onClick={() => handleClick(zPackage, user)}>
            <div className={'header'}>
                <div>{zPackage.name}</div>
                {getLockIcon(zPackage.isPackageFree)}

            </div>
            <hr />
            <div className={'body'}>
                <span>{zPackage.totalQuestion} savol</span>
                <span>{zPackage.numberOfPictureQuestions} rasmli</span>
            </div>
            <div className={'footer'}>
                {zPackage.createdDateText}
            </div>

            {loginRequired &&
                <LoginModalComponent
                    text={'To\'plamni ko\'rish uchun sahifamizda hisob ochishingiz lozim.'}
                    showModal={true}/>
            }

            {showPrice &&
                <PriceModalComponent
                    text={'To\'plamni ko\'rish uchun uni sotib olishingiz kerak. Biz bilan telegram orqali bog\'laning:'}
                    showModal={true}/>
            }

        </div>
    );
};

export default PackageCard;
