import { FETCH_PACKAGES_BEGIN, FETCH_PACKAGES_SUCCESS, FETCH_PACKAGES_FAILURE } from './actionTypes';

export const fetchPackagesBegin = () => ({
    type: FETCH_PACKAGES_BEGIN
});

export const fetchPackagesSuccess = packages => ({
    type: FETCH_PACKAGES_SUCCESS,
    payload: { packages: packages }
});

export const fetchPackagesFailure = error => ({
    type: FETCH_PACKAGES_FAILURE,
    payload: { error }
});
