import { FETCH_USER_BEGIN, FETCH_USER_SUCCESS, FETCH_USER_FAILURE, UPDATE_USER_SORTING_OPTION, UPDATE_USER_FILTER_OPTION } from './actionTypes';

const initialState = {
    user: {},
    loading: false,
    error: null,
    sorting: 'name_a_z',
    filter: 'all'
};

export default function userReducer(state = initialState, action) {
    switch(action.type) {
        case FETCH_USER_BEGIN:
            return {
                ...state,
                loading: true,
                error: null
            };

        case FETCH_USER_SUCCESS:
            return {
                ...state,
                loading: false,
                user: action.payload.user
            };

        case FETCH_USER_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload.error,
                user: {}
            };

        case UPDATE_USER_SORTING_OPTION:
            return {
                ...state,
                sorting: action.payload.sorting
            };

        case UPDATE_USER_FILTER_OPTION:
              return {
                  ...state,
                  filter: action.payload.filter
              };

        default:
            return state;
    }
}
