import { FETCH_SAVED_QUESTIONS_BEGIN, FETCH_SAVED_QUESTIONS_SUCCESS, FETCH_SAVED_QUESTIONS_FAILURE } from './actionTypes';

const initialState = {
    savedQuestions: [],
    loading: false,
    error: null
};

export default function savedQuestionReducer(state = initialState, action) {
    switch(action.type) {
        case FETCH_SAVED_QUESTIONS_BEGIN:
            return {
                ...state,
                loading: true,
                error: null
            };

        case FETCH_SAVED_QUESTIONS_SUCCESS:
            return {
                ...state,
                loading: false,
                savedQuestions: action.payload.savedQuestions
            };

        case FETCH_SAVED_QUESTIONS_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload.error,
                savedQuestions: []
            };

        default:
            return state;
    }
}
