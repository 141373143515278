import {
    createBrowserRouter,
    Link,
    Route,
    RouterProvider,
    Routes,
} from "react-router-dom";
import Root from "./Root";

const router = createBrowserRouter([
    { path: "*", element: <Root /> },
]);

export default router;
