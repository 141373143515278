import './App.scss';
import HomeComponent from './components/home/HomeComponent';
import Price from './components/price/Price';
import Profile from './components/profile/Profile';
import PackageSingleView from './components/view/PackageSingleView';
import {
    BrowserRouter as Router,
    Route,
    Routes,
    RouterProvider
} from 'react-router-dom';
import store from './store/reduxStore'
import {Provider} from 'react-redux';
import PackageCard from "./components/package/PackageComponent";
import router from "./router";



function App() {
    return <RouterProvider router={router} />;
}

export default App;
