import { FETCH_USER_BEGIN, FETCH_USER_SUCCESS, FETCH_USER_FAILURE, UPDATE_USER_SORTING_OPTION, UPDATE_USER_FILTER_OPTION } from './actionTypes';

export const fetchUserBegin = () => ({
    type: FETCH_USER_BEGIN
});

export const fetchUserSuccess = user => ({
    type: FETCH_USER_SUCCESS,
    payload: { user: user }
});

export const fetchUserFailure = error => ({
    type: FETCH_USER_FAILURE,
    payload: { error }
});

export const updateUserSorting = sorting => ({
    type: UPDATE_USER_SORTING_OPTION,
    payload: { sorting }
});

export const updateUserFilter = filter => ({
    type: UPDATE_USER_FILTER_OPTION,
    payload: { filter }
});
