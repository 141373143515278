import { FETCH_SAVED_QUESTIONS_BEGIN, FETCH_SAVED_QUESTIONS_SUCCESS, FETCH_SAVED_QUESTIONS_FAILURE } from './actionTypes';

export const fetchSavedQuestionsBegin = () => ({
    type: FETCH_SAVED_QUESTIONS_BEGIN
});

export const fetchSavedQuestionsSuccess = savedQuestions => ({
    type: FETCH_SAVED_QUESTIONS_SUCCESS,
    payload: { savedQuestions: savedQuestions }
});

export const fetchSavedQuestionsFailure = error => ({
    type: FETCH_SAVED_QUESTIONS_FAILURE,
    payload: { error }
});
