import {useState} from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import {ModalStyle} from './Util';
import Modal from '@mui/material/Modal';
import './priceModal.scss';
const PriceModalComponent = ({text, showModal}) => {
    const [open, setOpen] = useState(showModal);

    return (
        <div>
            <Modal
                open={open}
                onClose={() => setOpen(false)}
                aria-labelledby='modal-modal-title'
                aria-describedby='modal-modal-description'
                className={'price-modal'}
            >
                <Box sx={ModalStyle}>
                    <Typography id='modal-modal-description' sx={{mt: 2}}>
                        <div>
                            {text}
                        </div>

                        <div className={'buttons'}>
                            <a href='https://t.me/zakovat_savollari_com' target={'_blank'}>Telegram - Bot</a>
                            <a href='https://t.me/zakovat_savollari_com' target={'_blank'}>Telegram - Admin</a>
                        </div>
                    </Typography>
                </Box>
            </Modal>


        </div>
    );
};

export default PriceModalComponent;
