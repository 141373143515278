import { fetchUserBegin, fetchUserSuccess, fetchUserFailure } from './actions';

export function fetchUser() {
    return dispatch => {
        dispatch(fetchUserBegin());
        return fetch(`${process.env.REACT_APP_API_URL}/api/user`, {
            method: 'GET',
            credentials: 'include'
        })
            .then(handleErrors)
            .then(res => res.json())
            .then(json => {
                dispatch(fetchUserSuccess(json));
                return json;
            })
            .catch(error => dispatch(fetchUserFailure(error)));
    };
}

// Helper function for error handling
function handleErrors(response) {
    if (!response.ok) {
        throw Error(response.statusText);
    }
    return response;
}
