import './price.scss';
import React, {Component} from 'react';
import MobileNavigation from '../BottomNavigation';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';
import Container from '@mui/material/Container';

const Item = styled(Paper)(() => ({
    backgroundColor: 'white',
    padding: 0,
    color: 'black',
}));

class Home extends React.Component {
    render() {
        return (
            <div className={'price-container'}>
                <Box sx={{flexGrow: 1}}>
                    <Grid container spacing={2} columns={16} className={'header'}>
                        <h2>Narxlar</h2>

                        <p>To'plamlarni sotib olishingiz, sahifamizning ishlash va yangi funksiyalar qo'shishili uchun ishlatiladi. Veb sahifamizni doimo yangi
                            savollar va funksiyalar bilan yangilab turamiz. O'zingizga qulay tarifni tanlab, to'lovni amalga oshiring va bilimingizni biz bilan boyiting.
                            <br/>
                            <br/>
                            Reklamalarni sahifamizga joylab bilimdonlarga xalaqt berishni biz yaxshi fikr deb bilmaymiz.
                        </p>
                    </Grid>
                </Box>
                <Container>
                    <Grid container spacing={4} className={'card-container'}>
                        <Grid item xs={12} md={3}>
                            <Item elevation={3}>
                                <div className={'card'}>
                                    <h2>To'plamli</h2>
                                    <h4>1000 so'm</h4>
                                    <p>Har bir to'plamni 1000 so'mga sotib oling va cheksiz ishlating!</p>
                                    <ul>
                                        <li>
                                            <svg className='active' viewBox='0 0 24 24'>
                                                <path fill='currentColor' d='M10,17L5,12L6.41,10.58L10,14.17L17.59,6.58L19,8M12,2A10,10 0 0,0 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12A10,10 0 0,0 12,2Z'></path>
                                            </svg>
                                            <span>5 To'plam + PDF faylari bepul</span>
                                        </li>
                                        <li>
                                            <svg className='active' viewBox='0 0 24 24'>
                                                <path fill='currentColor' d='M10,17L5,12L6.41,10.58L10,14.17L17.59,6.58L19,8M12,2A10,10 0 0,0 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12A10,10 0 0,0 12,2Z'></path>
                                            </svg>
                                            <span>Savollarni izlash - sotib olingan to'plamlar ichida</span>
                                        </li>
                                        <li>
                                            <svg className='active' viewBox='0 0 24 24'>
                                                <path fill='currentColor' d='M10,17L5,12L6.41,10.58L10,14.17L17.59,6.58L19,8M12,2A10,10 0 0,0 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12A10,10 0 0,0 12,2Z'></path>
                                            </svg>
                                            <span>Sotib olingan to'plamlarni cheksiz ishlatish</span>
                                        </li>
                                        <li>
                                            <svg className='inactive' viewBox='0 0 24 24'>
                                                <path fill='currentColor' d='M12,2C17.53,2 22,6.47 22,12C22,17.53 17.53,22 12,22C6.47,22 2,17.53 2,12C2,6.47 6.47,2 12,2M15.59,7L12,10.59L8.41,7L7,8.41L10.59,12L7,15.59L8.41,17L12,13.41L15.59,17L17,15.59L13.41,12L17,8.41L15.59,7Z'></path>
                                            </svg>
                                            <span>PDF formatda to'plamlarni yuklab olish</span>
                                        </li>
                                        <li>
                                            <svg className='inactive' viewBox='0 0 24 24'>
                                                <path fill='currentColor' d='M12,2C17.53,2 22,6.47 22,12C22,17.53 17.53,22 12,22C6.47,22 2,17.53 2,12C2,6.47 6.47,2 12,2M15.59,7L12,10.59L8.41,7L7,8.41L10.59,12L7,15.59L8.41,17L12,13.41L15.59,17L17,15.59L13.41,12L17,8.41L15.59,7Z'></path>
                                            </svg>
                                            <span>Savollarni izlash - hamma to'plamlar ichida</span>
                                        </li>
                                        <li>
                                            <svg className='inactive' viewBox='0 0 24 24'>
                                                <path fill='currentColor' d='M12,2C17.53,2 22,6.47 22,12C22,17.53 17.53,22 12,22C6.47,22 2,17.53 2,12C2,6.47 6.47,2 12,2M15.59,7L12,10.59L8.41,7L7,8.41L10.59,12L7,15.59L8.41,17L12,13.41L15.59,17L17,15.59L13.41,12L17,8.41L15.59,7Z'></path>
                                            </svg>
                                            <span>Barcha yangi savol va to'plamlar</span>
                                        </li>
                                    </ul>
                                </div>
                            </Item>
                        </Grid>
                        <Grid item xs={12} md={3}>
                            <Item elevation={3}>
                                <div className={'card'}>
                                    <h2>To'plamli + PDF</h2>
                                    <h4>1500 so'm</h4>
                                    <p>Har bir to'plamni 1500 so'mga sotib oling va cheksiz ishlating!</p>
                                    <ul>
                                        <li>
                                            <svg className='active' viewBox='0 0 24 24'>
                                                <path fill='currentColor' d='M10,17L5,12L6.41,10.58L10,14.17L17.59,6.58L19,8M12,2A10,10 0 0,0 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12A10,10 0 0,0 12,2Z'></path>
                                            </svg>
                                            <span>5 To'plam + PDF faylari bepul</span>
                                        </li>
                                        <li>
                                            <svg className='active' viewBox='0 0 24 24'>
                                                <path fill='currentColor' d='M10,17L5,12L6.41,10.58L10,14.17L17.59,6.58L19,8M12,2A10,10 0 0,0 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12A10,10 0 0,0 12,2Z'></path>
                                            </svg>
                                            <span>Savollarni izlash - sotib olingan to'plamlar ichida</span>
                                        </li>
                                        <li>
                                            <svg className='active' viewBox='0 0 24 24'>
                                                <path fill='currentColor' d='M10,17L5,12L6.41,10.58L10,14.17L17.59,6.58L19,8M12,2A10,10 0 0,0 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12A10,10 0 0,0 12,2Z'></path>
                                            </svg>
                                            <span>Sotib olingan to'plamlarni cheksiz ishlatish</span>
                                        </li>
                                        <li>
                                            <svg className='active' viewBox='0 0 24 24'>
                                                <path fill='currentColor' d='M10,17L5,12L6.41,10.58L10,14.17L17.59,6.58L19,8M12,2A10,10 0 0,0 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12A10,10 0 0,0 12,2Z'></path>
                                            </svg>
                                            <span>PDF formatda to'plamlarni yuklab olish</span>
                                        </li>
                                        <li>
                                            <svg className='inactive' viewBox='0 0 24 24'>
                                                <path fill='currentColor' d='M12,2C17.53,2 22,6.47 22,12C22,17.53 17.53,22 12,22C6.47,22 2,17.53 2,12C2,6.47 6.47,2 12,2M15.59,7L12,10.59L8.41,7L7,8.41L10.59,12L7,15.59L8.41,17L12,13.41L15.59,17L17,15.59L13.41,12L17,8.41L15.59,7Z'></path>
                                            </svg>
                                            <span>Savollarni izlash - hamma to'plamlar ichida</span>
                                        </li>
                                        <li>
                                            <svg className='inactive' viewBox='0 0 24 24'>
                                                <path fill='currentColor' d='M12,2C17.53,2 22,6.47 22,12C22,17.53 17.53,22 12,22C6.47,22 2,17.53 2,12C2,6.47 6.47,2 12,2M15.59,7L12,10.59L8.41,7L7,8.41L10.59,12L7,15.59L8.41,17L12,13.41L15.59,17L17,15.59L13.41,12L17,8.41L15.59,7Z'></path>
                                            </svg>
                                            <span>Barcha yangi savol va to'plamlar</span>
                                        </li>
                                    </ul>
                                </div>
                            </Item>
                        </Grid>
                        <Grid item xs={12} md={3}>
                            <Item elevation={3}>
                                <div className={'card'}>
                                    <h2>Yillik</h2>
                                    <h4>69 000 so'm</h4>
                                    <p>Yillik tarifga obuna bo'ling va hamma to'plamlardagi savollardan bahramand bo'ling. Tarifni amal qilish muddati 1 yil.</p>
                                    <ul>
                                        <li>
                                            <svg className='active' viewBox='0 0 24 24'>
                                                <path fill='currentColor' d='M10,17L5,12L6.41,10.58L10,14.17L17.59,6.58L19,8M12,2A10,10 0 0,0 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12A10,10 0 0,0 12,2Z'></path>
                                            </svg>
                                            <span>5 To'plam + PDF faylari bepul</span>
                                        </li>
                                        <li>
                                            <svg className='active' viewBox='0 0 24 24'>
                                                <path fill='currentColor' d='M10,17L5,12L6.41,10.58L10,14.17L17.59,6.58L19,8M12,2A10,10 0 0,0 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12A10,10 0 0,0 12,2Z'></path>
                                            </svg>
                                            <span><span>96</span> ta 30-savollik to'plamlar</span>
                                        </li>
                                        <li>
                                            <svg className='active' viewBox='0 0 24 24'>
                                                <path fill='currentColor' d='M10,17L5,12L6.41,10.58L10,14.17L17.59,6.58L19,8M12,2A10,10 0 0,0 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12A10,10 0 0,0 12,2Z'></path>
                                            </svg>
                                            <span><span>36</span> ta 10-savollik to'plamlar</span>
                                        </li>
                                        <li>
                                            <svg className='inactive' viewBox='0 0 24 24'>
                                                <path fill='currentColor' d='M12,2C17.53,2 22,6.47 22,12C22,17.53 17.53,22 12,22C6.47,22 2,17.53 2,12C2,6.47 6.47,2 12,2M15.59,7L12,10.59L8.41,7L7,8.41L10.59,12L7,15.59L8.41,17L12,13.41L15.59,17L17,15.59L13.41,12L17,8.41L15.59,7Z'></path>
                                            </svg>
                                            <span>PDF formatda to'plamlarni yuklab olish</span>
                                        </li>
                                        <li>
                                            <svg className='active' viewBox='0 0 24 24'>
                                                <path fill='currentColor' d='M10,17L5,12L6.41,10.58L10,14.17L17.59,6.58L19,8M12,2A10,10 0 0,0 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12A10,10 0 0,0 12,2Z'></path>
                                            </svg>
                                            <span>Savollarni izlash - hamma to'plamlar ichida</span>
                                        </li>
                                        <li>
                                            <svg className='active' viewBox='0 0 24 24'>
                                                <path fill='currentColor' d='M10,17L5,12L6.41,10.58L10,14.17L17.59,6.58L19,8M12,2A10,10 0 0,0 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12A10,10 0 0,0 12,2Z'></path>
                                            </svg>
                                            <span>Barcha yangi savol va to'plamlar</span>
                                        </li>
                                    </ul>
                                </div>
                            </Item>
                        </Grid>
                        <Grid item xs={12} md={3}>
                            <Item elevation={3}>
                                <div className={'card'}>
                                    <h2>Yillik + PDF</h2>
                                    <h4>99 000 so'm</h4>
                                    <p>Yillik tarifga obuna bo'ling va hamma to'plamlardagi savollardan bahramand bo'ling. Tarifni amal qilish muddati 1 yil.</p>
                                    <ul>
                                        <li>
                                            <svg className='active' viewBox='0 0 24 24'>
                                                <path fill='currentColor' d='M10,17L5,12L6.41,10.58L10,14.17L17.59,6.58L19,8M12,2A10,10 0 0,0 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12A10,10 0 0,0 12,2Z'></path>
                                            </svg>
                                            <span><span>96</span> ta 30-savollik to'plamlar</span>
                                        </li>
                                        <li>
                                            <svg className='active' viewBox='0 0 24 24'>
                                                <path fill='currentColor' d='M10,17L5,12L6.41,10.58L10,14.17L17.59,6.58L19,8M12,2A10,10 0 0,0 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12A10,10 0 0,0 12,2Z'></path>
                                            </svg>
                                            <span><span>36</span> ta 10-savollik to'plamlar</span>
                                        </li>
                                        <li>
                                            <svg className='active' viewBox='0 0 24 24'>
                                                <path fill='currentColor' d='M10,17L5,12L6.41,10.58L10,14.17L17.59,6.58L19,8M12,2A10,10 0 0,0 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12A10,10 0 0,0 12,2Z'></path>
                                            </svg>
                                            <span>PDF formatda to'plamlarni yuklab olish</span>
                                        </li>
                                        <li>
                                            <svg className='active' viewBox='0 0 24 24'>
                                                <path fill='currentColor' d='M10,17L5,12L6.41,10.58L10,14.17L17.59,6.58L19,8M12,2A10,10 0 0,0 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12A10,10 0 0,0 12,2Z'></path>
                                            </svg>
                                            <span>Savollarni izlash - hamma to'plamlar ichida</span>
                                        </li>
                                        <li>
                                            <svg className='active' viewBox='0 0 24 24'>
                                                <path fill='currentColor' d='M10,17L5,12L6.41,10.58L10,14.17L17.59,6.58L19,8M12,2A10,10 0 0,0 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12A10,10 0 0,0 12,2Z'></path>
                                            </svg>
                                            <span>Barcha yangi savol va to'plamlar</span>
                                        </li>
                                        <li>
                                            <svg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24'>
                                                <path d='M12 24c6.627 0 12-5.373 12-12s-5.373-12-12-12-12 5.373-12 12 5.373 12 12 12zm1-6h-2v-8h2v8zm-1-12.25c.69 0 1.25.56 1.25 1.25s-.56 1.25-1.25 1.25-1.25-.56-1.25-1.25.56-1.25 1.25-1.25z'></path>
                                            </svg>
                                            <span>Kunlik PDF saqlab olish chegarasi: <span>30</span></span>
                                        </li>
                                    </ul>
                                </div>
                            </Item>
                        </Grid>
                    </Grid>
                </Container>

                <div className={'price-modal'}>
                    <div className={'buttons'}>
                        <a href='https://t.me/zakovat_savollari_com' target={'_blank'}>Telegram - Bot</a>
                        <a href='https://t.me/zakovat_savollari_com' target={'_blank'}>Telegram - Admin</a>
                    </div>
                </div>

                <MobileNavigation/>
            </div>
        )
    }
}

export default Home;
