import {fetchSavedQuestionsBegin, fetchSavedQuestionsSuccess, fetchSavedQuestionsFailure} from './actions';

function fetchSavedQuestions() {

    return dispatch => {
        dispatch(fetchSavedQuestionsBegin());
        return fetch(`${process.env.REACT_APP_API_URL}/api/savedQuestion/all`, {
                method: 'GET',
                credentials: 'include'
            })
            .then(handleErrors)
            .then(res => res.json())
            .then(json => {
                dispatch(fetchSavedQuestionsSuccess(json));
                return json;
            })
            .catch(error => dispatch(fetchSavedQuestionsFailure(error)));
    };
}

export default fetchSavedQuestions;

function handleErrors(response) {
    if (!response.ok) {
        throw Error(response.statusText);
    }
    return response;
}
